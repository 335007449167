import React from 'react';
import QueueAnim from 'rc-queue-anim';
import { Row } from 'antd';
import { page1 } from './data';
// import topTiph from '../assets/imgs/topTiph.png'


export default function Page1(props) {
  // const data1 = page1.slice(0, 2);
  // const data2 = page1.slice(2, 4);
  const renderCard = (data) => {
    const cardHtml = data.map((card, idx) => (
      <div className={'item-feature item'+idx}  key={idx.toString()}>
        {
          idx % 2 === 0 ? (<>
            <div className={'cardInfo card'+idx % 2}>
              <img className="cardIcon" src={props.isMobile ? card.mobImg : card.img}  alt='card'/>
              <div className="cardTitle">{card.title}</div>
              <div className="cardDetail">{card.description}</div>
             </div>
             
          </>) : (<>
            <div className={'cardInfo card'+idx % 2}>
              <img className="cardIcon" src={props.isMobile ? card.mobImg : card.img}  alt='card'/>
              <div className="cardTitle">{card.title}</div>
              <div className="cardDetail">{card.description}</div>
             </div>
          </>)
        }
       
      </div>
    ));
    return cardHtml;
  }

  const children = renderCard(page1);
  // const children1 = renderCard(data1);
  // const children2 = renderCard(data2);
  // page1
  return (
    <section id="product" className={'block1'+(props.isMobile ? '-mobile' : '')}>
      {/* {!props.isMobile ? <img src={websiteBg} className="page1-bg-img" alt="banner" /> : null} */}
      <div className="topTip">
          <h2>CARACTERíSTICAS</h2>
          {/* <img src={topTiph} alt="topTiph"/> */}
          <div>Nuestro objetivo es facilitar los préstamos, ayudar a más usuarios necesitados y construir la relación.</div>
      </div>
      <div className="page-main-content">
        {/* <p className="fsTitle">{'Features'}</p>
        <p className="fsDesc">{'Our objective is to make loans easier, help more needy users, and build rapport.'}</p> */}
         <QueueAnim
              component={Row}
              type="bottom"
              className="page row text-left one-row-page"
              delay={500}
            >
             <div className='page-main-feature'>{children}</div>
            </QueueAnim>
        {/* {
          !props.isMobile ? (
            <QueueAnim
              component={Row}
              type="bottom"
              className="page row text-left one-row-page"
              delay={500}
            >
              {children}
            </QueueAnim>) : (
              <div>
                <QueueAnim
                  component={Row}
                  type="bottom"
                  className="page row text-left one-row-page"
                  delay={500}
                >
                  {children1}
                </QueueAnim>
                <QueueAnim
                  component={Row}
                  type="bottom"
                  className="page row text-left one-row-page clear-padding"
                  delay={500}
                >
                  {children2}
                </QueueAnim>
              </div>
            )
        } */}
      </div>
    </section>);
}
