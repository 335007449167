import React, { Component } from 'react';
import Home from './Home';
import PrivacyAgreement from './Page/privacypolicy1';
import ServiceAgreement from './Page/ServiceAgreement';
import ErrorInfo from './Page/ErrorInfo';
import { HashRouter as Router, Route } from "react-router-dom";

class App extends Component {
  render() {
    console.log('----App----')
    return (
      <Router>
        <Route exact path="/" component={Home} />
        <Route exact path="/privacypolicy1" component={PrivacyAgreement} />
        <Route exact path="/serviceAgreement" component={ServiceAgreement} />
        <Route exact path="/errorInfo" component={ErrorInfo} />
      </Router>
    );
  }
}

export default App;
